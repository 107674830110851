<!--
 * @Author: your name
 * @Date: 2021-01-25 17:41:46
 * @LastEditTime: 2021-01-25 18:13:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT_Web_test\src\views\productionManagement\productionDelivery\updateDate\index.vue
-->
<template>
    <div class="updateDate">
        <div>
            <h4>选择时间：</h4>
            <div>
                <el-date-picker
                    v-model="timeData"
                    type="datetime"
                    placeholder="选择日期时间"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
            </div>
        </div>
        <footer>
            <el-button @click="cancel">
                取消
            </el-button>
            <el-button type="primary" @click="confirm">
                确认
            </el-button>
        </footer>
    </div>
</template>

<script>
export default {
    // 更新日期
    name: 'update-date',
    components: {},
    props: {
        extr: {
            type: [Array, Object, String ],
        },
    },
    data() {
        return {
            timeData: '',
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    destroyed() {},
    methods: {
        cancel() {
            this.$parent.hide();
        },
        confirm() {
            this.extr.sureCallback(this.timeData);
            this.$parent.hide();
        },
    },
};
</script>
<style lang="stylus" scoped>
.updateDate
    height 100%
    padding 0.4rem
    >div
        display flex
        h4
            flex 2
            text-align center
            font-size 0.16rem
            line-height 0.36rem
        >div
            flex 8
            .el-date-editor
                width 100% !important
    footer
        position absolute
        bottom 0.3rem
        left 0
        width 100%
        text-align center
        button
            width 1.4rem
            margin 0 0.2rem
</style>